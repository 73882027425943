import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { Toaster } from 'react-hot-toast'


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>

    {/* For notifications like login successfully */}
      <Toaster    
      position="top-center"
      reverseOrder={true}
      />

    <App />

  </Provider>
);
