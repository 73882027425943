import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Formik, Form, ErrorMessage, Field } from "formik";
import axios from "axios";

import { useAllLedgerDataHook } from "../hooks/useLedgerDataHook";
import MiniLoading from "../components/MiniLoading";
import Alert from "../components/Alert";
import { ENTRY_NARRATION_MAX_LENGTH } from "../constants/policies";
import { EntryCreateSchema } from "../util/entryValidationSchema";
import { useNavigate } from "react-router-dom";
import { CREATE_ENTRY_URL } from "../constants/api";
import toast from "react-hot-toast";

export default function CreateEntry() {
  const { token, accountType } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const fileInputRef = useRef(null); // Create a ref for the file input

  const {
    isLoading: isFetching,
    error: fetchingError,
    isError: isFetchingError,
    data: fetchedData,
  } = useAllLedgerDataHook(token);

  const initialFormData = {
    debit_ledger_id: "",
    credit_ledger_id: "",
    amount: 0,
    narration: "",
    date: "",
  };

  const [file, setFile] = useState(null); // State to store the file

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData = new FormData();
      formData.append("debit_ledger_id", values.debit_ledger_id);
      formData.append("credit_ledger_id", values.credit_ledger_id);
      formData.append("amount", values.amount);
      formData.append("narration", values.narration);
      formData.append("date", values.date);
      if (file) {
        formData.append("attachment", file);
      }

      const response = await axios.post(CREATE_ENTRY_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        resetForm(); // Reset form fields after successful submission
        setFile(null); // Clear the file state
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Clear the file input
        }
        toast.success("Entry created successfully")
      } else {
        console.error("Unexpected response status:", response.status); // Log unexpected response status
        throw new Error("Failed to add entry");
      }
    } catch (error) {
      console.error("Error adding entry:", error);
      // Handle error state, perhaps set an error message state to display to the user
    } finally {
      setSubmitting(false); // Ensure setSubmitting is called to enable form button
    }
  };

  useEffect(() => {
    if (accountType !== "admin") {
      navigate(-1);
    }
  }, [accountType]);

  return (
    <div className="p-4 bg-base-200">
      <center>
        <div className="card w-full max-w-sm bg-base-100">
          <div className="card-body sm:w-96 w-full">
            <div className="card-title">
              <h1 className="text-4xl font-bold">Create Entry</h1>
            </div>

            {isFetchingError && (
              <Alert message={fetchingError?.response?.data?.error?.message} />
            )}

            <Formik
              initialValues={initialFormData}
              validationSchema={EntryCreateSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, isSubmitting, resetForm }) => (
                <Form>
                  <div className="form-control">
                    <label className="label" htmlFor="debit_ledger_id">
                      <span className="label-text">Debit</span>
                      {isFetching ? <MiniLoading /> : null}
                    </label>
                    <Field
                      as="select"
                      className="select select-bordered capitalize"
                      name="debit_ledger_id"
                      autoFocus
                    >
                      <option value="">Select Debit A/c</option>
                      {fetchedData?.data?.ledgers?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name} A/c
                        </option>
                      ))}
                    </Field>
                    <span className="text-red-500 text-sm text-left">
                      <ErrorMessage name="debit_ledger_id" />
                    </span>
                  </div>

                  <div className="form-control">
                    <label className="label" htmlFor="credit_ledger_id">
                      <span className="label-text">Credit</span>
                    </label>
                    <Field
                      as="select"
                      className="select select-bordered capitalize"
                      name="credit_ledger_id"
                    >
                      <option value="">Select Credit A/c</option>
                      {fetchedData?.data?.ledgers?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name} A/c
                        </option>
                      ))}
                    </Field>
                    <span className="text-red-500 text-sm text-left">
                      <ErrorMessage name="credit_ledger_id" />
                    </span>
                  </div>

                  <div className="form-control">
                    <label className="label" htmlFor="amount">
                      <span className="label-text">Amount</span>
                    </label>
                    <Field
                      type="number"
                      placeholder="Amount"
                      className="input input-bordered"
                      name="amount"
                    />
                    <span className="text-red-500 text-sm text-left">
                      <ErrorMessage name="amount" />
                    </span>
                  </div>

                  <div className="form-control">
                    <label className="label" htmlFor="narration">
                      <span className="label-text">Narration</span>
                    </label>
                    <Field
                      as="textarea"
                      className="textarea textarea-bordered"
                      placeholder="Narration"
                      name="narration"
                    ></Field>
                    <label className="label">
                      <span
                        className={`label-text-alt ${
                          values?.narration?.length >
                          ENTRY_NARRATION_MAX_LENGTH
                            ? "text-red-500"
                            : null
                        }`}
                      >
                        ({values?.narration?.length}/
                        {ENTRY_NARRATION_MAX_LENGTH})
                      </span>
                    </label>
                    <span className="text-red-500 text-sm text-left">
                      <ErrorMessage name="narration" />
                    </span>
                  </div>

                  <div className="form-control">
                    <label className="label" htmlFor="date">
                      <span className="label-text">Date</span>
                    </label>
                    <Field
                      type="date"
                      className="input input-bordered"
                      name="date"
                    />
                    <span className="text-red-500 text-sm text-left">
                      <ErrorMessage name="date" />
                    </span>
                  </div>

                  <div className="form-control">
                    <label className="label" htmlFor="attachment">
                      <span className="label-text">Attachment</span>
                    </label>
                    <input
                      type="file"
                      className="input input-bordered"
                      name="attachment"
                      onChange={(event) => setFile(event.target.files[0])}
                      ref={fileInputRef} // Attach the ref to the file input
                    />
                  </div>

                  <div className="form-control mt-2">
                    <button
                      className={`btn btn-primary ${
                        isSubmitting ? "loading" : ""
                      }`}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </center>
    </div>
  );
}
