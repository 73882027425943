import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const StockistHomePage = () => {
  const { user } = useSelector((state) => state.auth);
  const [selectedLedgerId, setSelectedLedgerId] = useState("");

  const onChange = (e) => setSelectedLedgerId(e.target.value);

  return (
    <div className="p-4 bg-base-200">
      <center>
        <div className="card w-full max-w-sm bg-base-100">
          <div className="card-body sm:w-96 w-full">
            <div className="card-title">
              <h1 className="text-4xl font-bold">Select Ledger</h1>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Ledger</span>
              </label>
              <select
                className="select select-bordered capitalize"
                name="debit_ledger_id"
                onChange={onChange}
                value={selectedLedgerId}
                autoFocus
              >
                <option value="" label="Select a ledger" />

                {user?.ledgers &&
                  user?.ledgers.map((item , index) => (
                  <option value={item._id} key={index}>
                    {item.name} A/c
                  </option>
                ))}

              </select>
            </div>  
            <div className="form-control mt-2">
              <Link
                to={selectedLedgerId ? `/ledger/${selectedLedgerId}` : "#"}
                className="btn btn-primary"
                disabled={!selectedLedgerId}
              >
                View
              </Link>
            </div>
          </div>
        </div>
      </center>
    </div>
  );
};

export default StockistHomePage;









