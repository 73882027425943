import { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Loading from "../components/Loading";
import amountFormat from "../util/amountFormat";
import Alert from "../components/Alert";
import balanceIsNegative from "../util/balanceIsNegative";
import { useLedgerStatementDataHook } from "../hooks/useLedgerDataHook";
import toast from "react-hot-toast";
import { BASE_URL } from "../constants/api";

// Utility function to format date as Month Year
const getMonthYearString = (dateString) => {
  const date = new Date(dateString);
  const options = { month: "long", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

export default function ViewLedger() {
  const { token } = useSelector((state) => state.auth); // Redux selector to get auth token
  const { amountFormat: currencyFormat, currency } = useSelector((state) => state.preference); // Redux selector to get currency preferences
  const navigate = useNavigate(); // React Router hook for navigation
  const { id } = useParams(); // React Router hook to get URL parameters

  const [searchParams] = useSearchParams(); // React Router hook to get URL search params
  const [page, setPage] = useState(parseInt(searchParams.get("page")) || 1); // Local state for pagination, initialized from URL params

  const [statement, setStatement] = useState(); // Local state to store ledger statement data

  // Custom hook to fetch ledger statement data
  const { data, isLoading, isError, error } = useLedgerStatementDataHook(token, id, page - 1);

  // Effect to update URL with current page number
  useEffect(() => {
    navigate(`?page=${page}`);
  }, [navigate, page]);

  // Effect to update local state with fetched data
  useEffect(() => {
    setStatement(data?.data);
  }, [data]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  }

  const handleAttachmentClick = async (attachment) => {
  
    try {
      const response = await axios.post(
        `${BASE_URL}/entry/fetch-proof`,
        { fileName: attachment.name, filePath: attachment.path },
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const imageUrl = URL.createObjectURL(response.data);
      window.open(imageUrl, '_blank');
    } catch (error) {
      // console.error("Error fetching attachment:", error);
      toast.error("Error fetching attachment");
    }
  };

  return (
    <div className="p-4 bg-base-200 mb-auto">
      <center>
        <div className="w-full max-w-4xl sm:mt-4">
          <h1 className="text-4xl font-bold text-left mb-8">Ledger</h1>

          {isError ? (
            <Alert message={error?.response?.data?.error?.message} /> // Display error message if there's an error
          ) : (
            <div className="card bg-base-100">
              <div className="card-body w-full text-left py-4 px-6">
                <h1 className="text-2xs font-thin break-all uppercase text-justify">
                  #{id} {/* Display ledger ID */}
                </h1>
                <div className="grid grid-cols-2 gap-x-4 mb-4">
                  <div>
                    <h1 className="text-xl font-bold capitalize line-clamp-1">
                      {statement?.ledger?.name || "-"} A/c {/* Display ledger name */}
                    </h1>
                    <p className="text-sm break-words text-justify mt-2">
                      {statement?.ledger?.description} {/* Display ledger description */}
                    </p>
                  </div>
                  <div className="text-right">
                    <h1 className="text-2lg font-thin capitalize">
                      {statement?.balance > 0 ? "receivable" : "payable" || "-"} {/* Display ledger type */}
                    </h1>
                    <h1
                      className={`text-3xl font-thin break-all mt-2 ${
                        balanceIsNegative(
                          statement?.ledger?.type,
                          statement?.balance
                        )
                          ? "text-red-500"
                          : null
                      }`}
                    >
                      {amountFormat(
                        statement?.balance || 0,
                        currencyFormat,
                        currency
                      )} {/* Display ledger balance */}
                    </h1>
                  </div>
                </div>
                <div className="form-control mt-2">
                  <Link to={`edit`} className="btn btn-primary">
                    Edit {/* Link to edit ledger */}
                  </Link>
                </div>
              </div>
            </div>
          )}

          <h1 className="text-xl font-bold text-left mb-2 mt-4">Entries</h1>

          <p className="text-sm text-left mb-4">
            Page <span>{page}</span> of{" "}
            <span>{Math.ceil(statement?.total / statement?.limit) || 0}</span> {/* Display pagination info */}
          </p>

          {/* Entries Table */}
          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead>
                <tr>
                  <th className="table-header">S. No.</th>
                  <th className="table-header">Date</th>
                  <th className="table-header">Particulars</th>
                  <th className="table-header">Debit</th>
                  <th className="table-header">Credit</th>
                  <th className="table-header">Attachment</th> 
                </tr>
              </thead>
              <tbody>
                {statement?.entries?.map((entry , index) => (
                  <tr key={entry.id}>
                    <td className="table-cell">{index+1}</td> 
                    <td className="table-cell">{formatDate(entry.date)}</td> 
                    <td className="table-cell">{entry.narration}</td> 
                    <td className="table-cell">
                      {entry.debit_ledger.id === statement.ledger.id
                        ? amountFormat(entry.amount, currencyFormat, currency)
                        : "-"} 
                    </td>
                    <td className="table-cell">
                      {entry.credit_ledger.id === statement.ledger.id
                        ? amountFormat(entry.amount, currencyFormat, currency)
                        : "-"}
                    </td>
                    <td className="table-cell">
                      <button 
                        className="btn btn-secondary" 
                        onClick={() => handleAttachmentClick(entry.attachment)}
                      >
                        Attachment
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Buttons */}
          <div className="btn-group w-full max-w-sm mt-4">
            <button
              className="btn"
              disabled={page <= 1}
              onClick={() => setPage(page - 1)}
            >
              « Prev
            </button>
            <button className="btn">
              Page {page} of{" "}
              {Math.ceil(statement?.total / statement?.limit) || 0}
            </button>
            <button
              className="btn"
              disabled={page >= Math.ceil(statement?.total / statement?.limit)}
              onClick={() => setPage(page + 1)}
            >
              Next »
            </button>
          </div>

          <div className="mb-4">{isLoading ? <Loading /> : null}</div>

          {/* Show message if no entries */}
          {!isLoading && statement?.entries?.length === 0 ? (
            <h1 className="text-2xl font-thin text-left mb-4">No Data</h1>
          ) : null}
        </div>
      </center>
    </div>
  );
}
